<template>
    <v-container>
        <v-card elevation="2" outlined class="blue lighten-4">
            <v-card-title style="padding-bottom:0px;">
                <h4 class="primary--text">{{ audit.audit_id }}</h4>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"  style="margin-top: 15px;">
                        <v-select :items="projects" item-text="name" :rules="rules" v-model="selectProject" item-value="id" label="Select project" outlined></v-select>
                    </v-col>
                    <v-col cols="12"  style="margin-top:-35px;">
                        <p style="margin-bottom:0px; font-size:17px;" class="primary--text">Name of supervisor</p>
                        <v-text-field outlined :rules="rules" v-model="supervisor" class="pb-o"></v-text-field>
                    </v-col>
                    <v-col cols="12" style="margin-top:-35px;">
                        <p style="margin-bottom:0px; font-size:17px;" class="primary--text">Site name</p>
                        <v-text-field outlined :rules="rules" v-model="site" class="pb-o"></v-text-field>
                    </v-col>
                    <v-col cols="12" style="margin-top:-35px;">
                        <p style="margin-bottom:5px; font-size:17px;" class="primary--text">Scope of Audit (Tasks being inspected)</p>
                        <v-textarea outlined auto-grow rows="4" :rules="rules" v-model="tasks" class="pb-o"></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            
            <v-card-actions>
                <v-btn style="text-transform: capitalize;margin-top:-30px;margin-left: 8px;" class="primary" @click.once="submitAudit()">Submit</v-btn>
            </v-card-actions>
        </v-card>      
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name: "AuditReview",
    data:() => ({
        user_p : JSON.parse(localStorage.getItem('user')),
        selectProject:"",
        site:"",
        supervisor:"",
        tasks:"",
        rules: [
            value => !!value || "Required."
        ],
    }),
    computed:{
        ...mapGetters (["getAllProjects","getCurrentAudit" ]),
        projects () {
            return this.getAllProjects
        },
        audit () {
            return this.getCurrentAudit
        }
    },
    mounted () {
        this.fetchAllProjects('audit')
    },
    methods: {
        ... mapActions (['fetchAllProjects']),
        async submitAudit () {
            let formData = new FormData();
            formData.append("tasks",this.tasks)
            formData.append("project", this.selectProject)
            formData.append("user", this.user_p.id)
            formData.append("supervisor", this.supervisor)
            formData.append("site", this.site)
            formData.append("audit", this.audit.id)
            axios.post(
                "https://camusatweb.ubuniworks.com/api/v1/audit/review",
                formData
            ).then((response) => {
                if(response.data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'Audit and Inspection successfully submitted',
                        text: 'Continue to complete the checklist'
                    })
                    this.$store.commit('setReviewAudit', response.data.response )
                    this.$router.push('/audit/questions')
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Audit and Inspection Failed',
                        text: response.data.message
                    })
                }
            })
        }
    }
}
</script>